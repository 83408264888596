import { useCallback } from "react";
import { animated } from "react-spring";
import { Boop } from "../Animations/Boop";
import Tooltip from "react-bootstrap/Tooltip";
import { isMobile } from "react-device-detect";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { ImgWithFallback } from "../ImgWithFallback/ImgWithFallback";
import { items_state, update_items_activeIds } from "../../dataManagers/GlobalDataManagers";
import { useAtom } from "jotai";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";
import _ from "lodash";

const glove_shell_nativeKip_ids = [
  "palm_nativeKip_leather",
  "shellBase_nativeKip_leather__skippedWeltFielders",
  "shellAccent_nativeKip_leather__singleWeltFielders",
  "shellAccent_nativeKip_leather__skippedWeltFielders",
  "shellTrim_nativeKip_leather__skippedWeltFielders",
  "shellBase_nativeKip_leather__catchers",
  "shellWings_nativeKip_leather",
  "shellExtra_nativeKip_leather",
  "wristStrap_nativeKip_leather",
  "web_nativeKip_leather",
  "basket_web_nativeKip_leather",
  "shellAccent_nativeKip_leather__fourFinger",
  "shellAccent_nativeKip_leather__crownFielders",
  "shellAccent_nativeKip_leather__closedCatchers",
  "shellAccent_nativeKip_leather__openCatchers",
  "indexFinger_nativeKip_leather",
  "shellTrim_nativeKip_leather__openCatchers",
  "shellLips_nativeKip_leather",
  "shellTrim_nativeKip_leather__closedCatchers",
  "shellMidFinger_nativeKip_leather__closedCatchers",
  "fingerCover_nativeKip_leather",
  "shellBase_nativeKip_leather__crownFielders",
  "shellTrim_nativeKip_leather__crownFielders",
  "thumb_nativeKip_leather",
  "fingers_nativeKip_leather",
  "wrist_nativeKip_leather",
  "band_nativeKip_leather",
  "shellBase_nativeKip_leather__fourFinger",
  "shellTrim_nativeKip_leather__fourFinger",
  "shellBase_nativeKip_leather__singleWeltFielders",
  "shellTrim_nativeKip_leather__singleWeltFielders",
  "fingerPad_nativeKip_leather",
];

const glove_shell_steerhide_ids = [
  "palm_steerhide_leather",
  "shellAccent_steerhide_leather__singleWeltFielders",
  "shellBase_steerhide_leather__skippedWeltFielders",
  "shellAccent_steerhide_leather__skippedWeltFielders",
  "shellTrim_steerhide_leather__skippedWeltFielders",
  "shellBase_steerhide_leather__singleWeltFielders",
  "shellBase_steerhide_leather__catchers",
  "shellWings_steerhide_leather",
  "shellExtra_steerhide_leather",
  "wristStrap_steerhide_leather",
  "web_steerhide_leather",
  "basket_web_steerhide_leather",
  "shellAccent_steerhide_leather__fourFinger",
  "shellAccent_steerhide_leather__crownFielders",
  "shellAccent_steerhide_leather__closedCatchers",
  "shellAccent_steerhide_leather__openCatchers",
  "indexFinger_steerhide_leather",
  "shellTrim_steerhide_leather__openCatchers",
  "shellLips_steerhide_leather",
  "shellTrim_steerhide_leather__closedCatchers",
  "shellMidFinger_steerhide_leather__closedCatchers",
  "fingerCover_steerhide_leather",
  "shellBase_steerhide_leather__crownFielders",
  "shellTrim_steerhide_leather__crownFielders",
  "thumb_steerhide_leather",
  "fingers_steerhide_leather",
  "wrist_steerhide_leather",
  "band_steerhide_leather",
  "shellBase_steerhide_leather__fourFinger",
  "shellTrim_steerhide_leather__fourFinger",
  "shellTrim_steerhide_leather__singleWeltFielders",
  "fingerPad_steerhide_leather",
];

const glove_shell_texas_steerhide_ids = [
  "palm_texas_steerhide_leather",
  "shellBase_texas_steerhide_leather__skippedWeltFielders",
  "shellAccent_texas_steerhide_leather__skippedWeltFielders",
  "shellTrim_texas_steerhide_leather__skippedWeltFielders",
  "shellBase_texas_steerhide_leather_catchers",
  "shellWings_texas_steerhide_leather",
  "shellExtra_texas_steerhide_leather",
  "wristStrap_texas_steerhide_leather",
  "web_texas_steerhide_leather",
  "basket_web_texas_steerhide_leather",
  "shellAccent_texas_steerhide_leather_fourFinger",
  "shellAccent_texas_steerhide_leather__singleWeltFielders",
  "shellAccent_texas_steerhide_leather_singleWeltFielders",
  "shellAccent_texas_steerhide_leather_crownFielders",
  "shellAccent_texas_steerhide_leather_closedCatchers",
  "shellAccent_texas_steerhide_leather_openCatchers",
  "indexFinger_texas_steerhide_leather",
  "shellTrim_texas_steerhide_leather_openCatchers",
  "shellLips_texas_steerhide_leather",
  "shellTrim_texas_steerhide_leather_closedCatchers",
  "shellMidFinger_texas_steerhide_leather_closedCatchers",
  "fingerCover_texas_steerhide_leather",
  "shellBase_texas_steerhide_leather__crownFielders",
  "shellTrim_texas_steerhide_leather__crownFielders",
  "thumb_texas_steerhide_leather",
  "fingers_texas_steerhide_leather",
  "wrist_texas_steerhide_leather",
  "band_texas_steerhide_leather",
  "shellBase_texas_steerhide_leather__fourFinger",
  "shellTrim_texas_steerhide_leather_fourFinger",
  "shellBase_texas_steerhide_leather__singleWeltFielders",
  "shellTrim_texas_steerhide_leather__singleWeltFielders",
  "fingerPad_texas_steerhide_leather",
];

export const ImageItem = ({ gloveComponent, item, animation }) => {
  const [itemsState] = useAtom(items_state);
  const [, setItemsActiveIds] = useAtom(update_items_activeIds);
  const isAllowedColors = gloveComponent._id === "allowed_colors";
  const isAllowedSteerhide = gloveComponent._id === "allowed_colors_steerhide";
  const isAllowedTexasSteerhide = gloveComponent._id === "allowed_colors_texas_steerhide";
  const section = gloveComponent.sections[0];
  let isActive;

  if (section === "steerhide") {
    if (isAllowedSteerhide) {
      isActive = itemsState.activeIds[gloveComponent._id]?.some(({ _id }) => _id === item._id) ? "activeImage" : null;
    } else {
      isActive = itemsState.activeIds[gloveComponent._id]._id === item._id ? "activeImage" : null;
    }
  } else if (section === "nativeKip") {
    if (isAllowedColors) {
      isActive = itemsState.activeIds[gloveComponent._id]?.some(({ _id }) => _id === item._id) ? "activeImage" : null;
    } else {
      isActive = itemsState.activeIds[gloveComponent._id]._id === item._id ? "activeImage" : null;
    }
  } else if (section === "texas") {
    if (isAllowedTexasSteerhide) {
      isActive = itemsState.activeIds[gloveComponent._id]?.some(({ _id }) => _id === item._id) ? "activeImage" : null;
    } else {
      isActive = itemsState.activeIds[gloveComponent._id]._id === item._id ? "activeImage" : null;
    }
  } else {
    isActive = itemsState.activeIds[gloveComponent._id]._id === item._id ? "activeImage" : null;
  }

  const { incompatibleItems } = item;
  let isDisabled = false;

  // checks if this item has any incompatible items
  if (incompatibleItems) {
    // loops through the incompatible items and checks if any of them are currently active
    isDisabled = incompatibleItems.some((disabledItem) => Object.values(itemsState.activeIds).some(({ _id }) => _id === disabledItem));
  }

  // disable item if it's not in allowed colors
  if (section === "steerhide") {
    if (gloveComponent.category === "colors" && !isAllowedSteerhide && !itemsState.activeIds.allowed_colors_steerhide.some(({ _id }) => _id === item._id)) {
      isDisabled = true;
    }
  } else if (section === "nativeKip") {
    if (gloveComponent.category === "colors" && !isAllowedColors && !itemsState.activeIds.allowed_colors.some(({ _id }) => _id === item._id)) {
      isDisabled = true;
    }
  } else if (section === "texas") {
    if (
      gloveComponent.category === "colors" &&
      !isAllowedTexasSteerhide &&
      !itemsState.activeIds.allowed_colors_texas_steerhide.some(({ _id }) => _id === item._id)
    ) {
      isDisabled = true;
    }
  }

  const handleClick = () => {
    // abort if active item was reclicked
    if (itemsState.activeIds[gloveComponent._id]._id === item._id) return;

    let copy = { ...itemsState.activeIds };

    if (gloveComponent._id.includes("allowed_colors")) {
      if (copy[gloveComponent._id].length < 2) {
        copy[gloveComponent._id].push({ _id: item._id });
      } else {
        copy[gloveComponent._id] = [{ _id: item._id }];
      }

      const allowedColors = copy[gloveComponent._id].map(({ _id }) => _id);
      if (section === "steerhide") {
        glove_shell_steerhide_ids.forEach((shellId) => {
          if (itemsState.activeIds[shellId] && !allowedColors.includes(itemsState.activeIds[shellId]._id)) {
            copy[shellId] = { _id: item._id };
          }
        });
      } else if (section === "nativeKip") {
        glove_shell_nativeKip_ids.forEach((shellId) => {
          if (itemsState.activeIds[shellId] && !allowedColors.includes(itemsState.activeIds[shellId]._id)) {
            copy[shellId] = { _id: item._id };
          }
        });
      } else if (section === "texas") {
        glove_shell_texas_steerhide_ids.forEach((shellId) => {
          if (itemsState.activeIds[shellId] && !allowedColors.includes(itemsState.activeIds[shellId]._id)) {
            copy[shellId] = { _id: item._id };
          }
        });
      }
    } else {
      copy[gloveComponent._id] = { _id: item._id };
    }

    if (!_.isEmpty(item.inputs)) copy[gloveComponent._id].inputs = item.inputs;

    setItemsActiveIds(copy);

    pushGTMAnalyticsEvent({
      event: "Item Click - Bradley",
      category: gloveComponent.category,
      itemSection: gloveComponent.displayName,
      item: item.displayName,
    });
  };

  const tooltip = (props) => (
    // transitionDelay prevents jitter on safari
    <Tooltip {...props} style={{ zIndex: 3000, transitionDelay: "200ms", ...props.style }} id={`tooltip-details-${item._id}`}>
      {item.displayName}
    </Tooltip>
  );

  return (
    <animated.div style={animation}>
      <Boop boopType="scale" scale={1.02} timing={200}>
        {isActive && <img className="checkMark" alt="check mark" src="/images/checkmark.svg" />}
        <OverlayTrigger key={item._id} placement="bottom" overlay={isMobile ? <></> : tooltip}>
          <div className={`${isActive} ${isDisabled ? "disabled" : null}`}>
            <ImgWithFallback
              className={`imageItems ${gloveComponent.category}`}
              src={item.thumbnail[0]}
              fallback={item.thumbnail[1] || item.thumbnail[0]}
              onClick={handleClick}
            />
          </div>
        </OverlayTrigger>
      </Boop>
    </animated.div>
  );
};
