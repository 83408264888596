import { useAtom } from "jotai";
import { useEffect, useMemo } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { animated, useSpring } from "react-spring";
import { items_state, update_items_activeIds } from "../../dataManagers/GlobalDataManagers";
import { Line } from "../Line/Line";
import "./styles.scss";

export const FontSection = ({ componentItems, gloveComponent, ...props }) => {
  const [itemsState] = useAtom(items_state);
  const [, setItemsActiveIds] = useAtom(update_items_activeIds);
  const [animationProps, animate] = useSpring(() => ({ from: { opacity: 0, scale: 0 } }));
  const customTextItem = useMemo(() => itemsState.array.find((item) => item._id === props.itemId), []);

  useEffect(() => {
    animate.start({ from: { opacity: 0, scale: 0 }, to: { opacity: 1, scale: 1 } });
  }, []);

  const handleClick = (index) => {
    // abort if font selected is the same as the current active font
    if (customTextItem.inputs.active_font_index === index) return;

    const copy = { ...itemsState.activeIds };

    let newActiveInput = {};

    newActiveInput._id = customTextItem._id;
    newActiveInput.inputs = customTextItem.inputs;

    newActiveInput.inputs.active_font_index = index;

    copy[gloveComponent._id] = newActiveInput;
    setItemsActiveIds(copy);
  };

  const fontItems = customTextItem.font_array.map((font, index) => {
    const isFontActive = index === itemsState.activeObjs[gloveComponent._id].active_font_index;

    return (
      <Col key={font.displayName} xs={4}>
        <div onClick={() => handleClick(index)} style={{ cursor: "pointer" }}>
          <div className={`fontContainer px-2 py-1 ${isFontActive ? "active" : "disabled"}`}>
            <img src={font.preview_img_src} alt="Font" />
          </div>
        </div>
      </Col>
    );
  });

  return (
    <animated.div style={animationProps}>
      <div className="FontSection my-2">
        <div className="d-flex flex-column mb-2 text-center">
          <h6 className="mb-1">Choose Font:</h6>
          <Line color="black" height="1px" width="30px" boxShadow="none" />
        </div>
        <Row className="fontItems text-center mt-3">{fontItems}</Row>
      </div>
    </animated.div>
  );
};
