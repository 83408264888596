import React from "react";
import MaintenanceNotice from "../MaintenanceNotice/MaintenanceNotice.jsx";
import "./styles.scss";

export const BuilderScreen = ({ children }) => {
  return (
    <>
      {/* <MaintenanceNotice /> */}
      <div className="BuilderScreen" style={{ height: `100%`, width: `100%` }}>
        {children}
      </div>
    </>
  );
};
