import { animated } from "react-spring";
import { Boop } from "../Animations/Boop";
import { items_state, update_items_activeIds } from "../../dataManagers/GlobalDataManagers";
import { useAtom } from "jotai";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";

export const TextItem = ({ item, gloveComponent, animation }) => {
  const [itemsState] = useAtom(items_state);
  const [, setItemsActiveIds] = useAtom(update_items_activeIds);
  const isActive = itemsState.activeIds[gloveComponent._id]._id === item._id ? "activeText" : null;
  const { incompatibleItems } = item;
  let isDisabled = false;

  // checks if this item has any incompatible items
  if (incompatibleItems) {
    // loops through the incompatible items and checks if any of them are currently active
    isDisabled = incompatibleItems.some((disabledItem) => Object.values(itemsState.activeIds).some(({ _id }) => _id === disabledItem));
  }

  const handleClick = () => {
    // abort if active item was reclicked
    if (itemsState.activeIds[gloveComponent._id]._id === item._id) return;

    let copy = { ...itemsState.activeIds };
    copy[gloveComponent._id] = { _id: item._id };

    setItemsActiveIds(copy);

    pushGTMAnalyticsEvent({
      event: "Item Click - Bradley",
      category: gloveComponent.category,
      itemSection: gloveComponent.displayName,
      item: item.displayName,
    });
  };

  return (
    <animated.div style={animation}>
      <Boop boopType="scale" scale={1.02} timing={200}>
        <button onClick={handleClick} className={`${isActive} ${isDisabled ? "disabled" : null}`}>
          {item.displayName}
        </button>
      </Boop>
    </animated.div>
  );
};
